function hitungHariKerja(tanggalMulai, tanggalSelesai) {
  tanggalMulai = new Date(tanggalMulai);
  tanggalMulai.setHours(0, 0, 0, 0);
  tanggalSelesai = new Date(tanggalSelesai);
  tanggalSelesai.setHours(23, 59, 59, 999);

  let hariKerja = 0;
  let tanggalSekarang = new Date(tanggalMulai);

  // You'll need to implement a way to get holiday dates from your server
  // For this example, we'll assume you have a global variable holidayDates
  // that contains an array of holiday date strings in 'YYYY-MM-DD' format
  const tanggalLibur = window.holidayDates || [];

  while (tanggalSekarang <= tanggalSelesai) {
    const dayOfWeek = tanggalSekarang.getDay();
    const dateString = tanggalSekarang.toISOString().split("T")[0];

    // Check if it's not Saturday (6) or Sunday (0) and not a holiday
    if (
      dayOfWeek !== 0 &&
      dayOfWeek !== 6 &&
      !tanggalLibur.includes(dateString)
    ) {
      hariKerja++;
    }

    tanggalSekarang.setDate(tanggalSekarang.getDate() + 1);
  }

  return hariKerja;
}

// Make the function available globally
window.hitungHariKerja = hitungHariKerja;
